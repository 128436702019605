import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library, config } from "@fortawesome/fontawesome-svg-core";
import {
  faFileContract,
  faWarehouse,
  faCheck,
  faAngleDown,
  faLock,
  faTimes,
  faSpinner,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";

import {
  faCalendarCheck
} from "@fortawesome/free-regular-svg-icons"


library.add(
  faFileContract,
  faWarehouse,
  faCheck,
  faAngleDown,
  faLock,
  faCalendarCheck,
  faTimes,
  faSpinner,
  faInfoCircle
);
config.autoAddCss = false;

const plugin = {
  install: (Vue) => {
    Vue.component('font-awesome-icon', FontAwesomeIcon);
  }
}

FontAwesomeIcon.install = plugin.install;

export default FontAwesomeIcon;
