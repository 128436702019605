import Axios from "axios";
import {API_ROOT} from "@/constants/ApiRoot";

const PAYMENT_ENDPOINT = `${API_ROOT}/payment`;

export default {
  async submitPayment(paymentDto) {
    return await Axios.post(`${PAYMENT_ENDPOINT}`, paymentDto);
  },
  async getPaymentMethods(getPaymentMethodsDto) {
    return await Axios.get(`${PAYMENT_ENDPOINT}`, {params: getPaymentMethodsDto});
  },
  async savePaymentMethods(savePaymentMethodDto) {
    return await Axios.post(`${PAYMENT_ENDPOINT}/save`, savePaymentMethodDto);
  }
}
