<template>
  <div class="modal" role="dialog" id="modal" v-if="showModal">

    <div class="modal__content">
      <header class="modal__header">
        <slot name="header" />
      </header>

      <section class="modal__body">
        <slot name="body" />
      </section>

      <footer class="modal__footer">
        <slot name="footer" />
      </footer>

      <a href="#" class="modal__close" click.prevent="closeModal">&times;</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    showModal: Boolean,
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(77, 77, 77, .7);
  transition: all .4s;
  z-index: 1000;

  &__content {
    display: flex;
    border-radius: 4px;
    position: relative;
    width: 500px;
    max-width: 90%;
    background: #fff;
    padding: 1em 2em;
    min-height: 200px;
  }

  &__body {
    align-self: center;
  }

  &__footer {
    text-align: right;
    a {
      color: #585858;
    }
    i {
      color: #d02d2c;
    }
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #585858;
    text-decoration: none;
  }
}

</style>
