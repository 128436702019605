import { createWebHistory, createRouter } from "vue-router";
import UserInfo from "@/view/UserInfo";
import ContractSelect from "@/view/ContractSelect";
import Payment from "@/view/Payment";
import PaymentBreakdown from "@/view/PaymentBreakdown";
import Receipt from "@/view/Receipt";
import Confirmation from "@/view/Confirmation";
import Login from "@/view/Login";
import CreateAccount from "@/view/CreateAccount"
import VerifyAccount from "@/view/VerifyAccount"
import ResetUsername from "@/view/ResetUsername";
import ResetVerify from "@/view/ResetVerify";

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/register',
    name: 'Create Account',
    component: CreateAccount,
  },
  {
    path: '/verification',
    name: 'Verify Account Login',
    component: VerifyAccount,
  },
  {
    path: '/',
    name: 'UserInfo',
    component: UserInfo,
    props: {
      locationQuery: route => ({ query: route.query.locationId }),
      entityIdQuery: route => ({query: route.query.entityId}),
      customerIdQuery: route => ({ query: route.query.customerId }),
      centralCustomerQuery: route => ({ query: route.query.centralCustomerId }),
      monthsIntoFutureQuery: route => ({ query: route.query.monthsIntoFuture }),
      contractUnitIdsQuery: route => ({ query: route.query.contractUnitIds }),
      guestQuery: route => ({ query: route.query.guest })
    }
  },
  {
    path: '/reset',
    name: 'ResetUsername',
    component: ResetUsername,
  },
  {
    path: '/reset/verify',
    name: 'ResetVerify',
    component: ResetVerify
  },
  {
    path: '/contract',
    name: 'ContractSelect',
    component: ContractSelect,
  },
  {
    path: '/paymentBreakdown',
    name: 'PaymentBreakdown',
    component: PaymentBreakdown,
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment,
  },
  {
    path: '/confirmation',
    name: 'Confirmation',
    component: Confirmation
  },
  {
    path: '/receipts',
    name: 'Receipt',
    component: Receipt
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
