<template>
  <div class="form-container">
    <div class="form-container__field-container form-container__header">
      Login
    </div>
    <div class="form-container__field-container">
      <div v-if="this.lockout > 0" class="form-container__lockout">
        You have been locked out. Time Remaining:
        <v-idle :duration="this.lockout" :events="[]" :eventTickRate="1" @idle="handleIdle" @remind="handleLockout" />
      </div>
      <label for="username">Email Address or Phone Number</label>
      <input id="username" v-model="form.username" />
    </div>
    <div class="form-container__field-container">
      <label for="password">Password</label>
      <input id="password" type="password" v-model="form.password" />
    </div>
    <div class="form-container__field-container">
      <router-link class="form-container__forgot-password" to="/reset">Forgot Password?</router-link>
      <div class="form-container__remember">
        <checkbox :checked="checked" @check-clicked="rememberCheck" />
        <div class="form-container__remember-me">Remember Me</div>
      </div>
    </div>
    <div class="form-container__field-container">
      <button id="submitBtn" class="btn-primary" @click="submitForm">
        <font-awesome-icon v-if="loading" icon="spinner" class="fa-spin" />
        <span v-if="!loading">Login</span>
      </button>
    </div>
    <div class="form-container__field-container">
      <span class="form-container__or">or</span>
    </div>
    <div class="form-container__field-container">
      <router-link class="form-container__create-account" to="/register"><button class="btn-secondary">Create Account</button></router-link>
    </div>
    <div class="form-container__field-container">
      <router-link class="form-container__guest" :to="{ path: '/', query: { guest: true, locationId } }">Continue as Guest</router-link>
    </div>
    <the-footer class="form-container__field-container"/>
  </div>
</template>
<script>
import Checkbox from "@/components/Select/UnitSelect/Checkbox";
import RouterLink from 'vue-router';
import TheFooter from "@/components/SingleTime/TheFooter";
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useNotificationStore } from '@dafcoe/vue-notification';
import { isEmail } from '@/global/Helpers';
import { ErrorAlerts } from '@/constants/ErrorAlerts';

export default {
  name: "Login",
  components: {
    Checkbox,
    RouterLink,
    TheFooter
  },
  setup() {
    const store = useStore();
    const { setNotification } = useNotificationStore();
    const location = computed(() => store.getters['location/getLocationId']);
    const username = computed(() => store.getters['user/getUsername']);
    const lockout = computed(() => store.getters['user/getLockout']);
    const setUsername = (username) => store.dispatch('user/setUsername', username);
    const authenticateCustomer = (loginDto) => store.dispatch('user/authenticateCustomer', loginDto);
    const clearAll = () => store.dispatch('clearAll');
    const fetchLocation = (locationId) => store.dispatch('location/fetchLocation', locationId);
    const updateLocationId = (locationId) => store.dispatch('location/updateLocationId', locationId);
    const fetchLockout = (lockoutDto) => store.dispatch('user/fetchCustomerLockout', lockoutDto);
    const updateLockout = (time) => store.dispatch('user/updateLockout', time);

    return {
      location,
      username,
      lockout,
      clearAll,
      setUsername,
      setNotification,
      authenticateCustomer,
      fetchLocation,
      updateLocationId,
      fetchLockout,
      updateLockout
    }
  },
  created() {
    window.addEventListener('beforeunload', this.handleUnload);
  },
  computed: {
    lockoutTimer() {
      return this.lockoutCounter;
    }
  },
  data() {
    return {
      locationId: this.location,
      form: {
        username: this.username,
        password: ''
      },
      checked: this.username !== '',
      loading: false,
      lockoutCounter: this.lockout
    }
  },
  methods: {
    rememberCheck(data) {
      this.checked = data;
    },
    handleLockout(time) {
      this.lockoutCounter = time;
    },
    handleIdle() {
      this.updateLockout(0);
    },
    async submitForm() {
      try {
        this.loading = true;
        const username = this.checked ? this.form.username : '';
        await this.setUsername(username);
        await this.authenticateCustomer(this.form);
        await this.$router.push("/contract");
      } catch (error) {
        this.loading = false;
        this.setNotification({
          ...ErrorAlerts,
          message: error.response.data.errorMessage
        });
        if (error.response.data.errorMessage.includes('InvalidPassword') || error.response.data.errorMessage.includes('AccountLocked')) {
          const lockoutDto = isEmail(this.form.username) ? { email: this.form.username } : { phoneNumber: this.form.username };
          await this.fetchLockout(lockoutDto);
        }
        this.$log.error(error);
      }
    },
    handleUnload() {
      this.updateLockout(this.lockoutTimer);
    }
  },
  mounted() {
    this.clearAll();
    this.fetchLocation(this.location);
    this.updateLocationId(this.location);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.handleUnload);
  }
}
</script>

<style lang="scss" scoped>
.form-container {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: auto;
  grid-template-rows: 45px 90px 90px 35px 90px min-content 90px 25px 100px;
  grid-template-areas: "header" "username" "password" "rememberMe" "loginButton" "or" "createAccount" "guest" "footer";
  justify-content: center;
  padding: 36px 36px 10px 36px;
  color: $black;

  &__header {
    text-align: center;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: 600;
    font-size: 26px;
  }

  &__message {
    text-align: center;
    line-height: 20px;
    font-size: 16px;
    letter-spacing: 0;
  }

  &__remember {
    display: flex;
    justify-content: end;
    align-items: center;
  }

  &__or {
    &::before,
    &::after {
      display: inline-block;
      content: "";
      border-top: 1px solid $light-grey;
      width: 35%;
      margin: 0 1rem;
      transform: translateY(-.4rem);
    }
  }

  &__field-container:first-of-type {
    grid-area: header;
  }

  &__field-container:nth-of-type(2) {
    grid-area: username;
  }

  &__field-container:nth-of-type(3) {
    grid-area: password;
  }

  &__field-container:nth-of-type(4) {
    grid-area: rememberMe;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__field-container:nth-of-type(5) {
    position: relative;
    grid-area: loginButton;
  }

  &__field-container:nth-of-type(6) {
    grid-area: or;
    text-align: center;
  }

  &__field-container:nth-of-type(7) {
    position: relative;
    grid-area: createAccount;
  }

  &__field-container:nth-of-type(8) {
    grid-area: guest;
    text-align: center;
  }

  &__field-container:nth-of-type(9) {
    grid-area: footer;
  }

  &__lockout {
    color: $error;
  }
}

@media(max-width: $media-large) {
  .form-container {
    max-width: 350px;
  }
}

.v-idle {
  display: inline-block;
}
</style>
