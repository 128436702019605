export const getLocation = state => {
  return state.location;
};

export const getEntity = state => {
  return state.location.entity;
};

export const getLocationId = state => {
  return state.locationId;
};
